import React, { useEffect, useRef, useState } from 'react'
import './styles.scss';
import Button from 'components/button';
import FormGroup from 'components/form/formGroup';
import { useForm } from 'react-hook-form';
import Form from 'components/form';
import dayjs from 'dayjs';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getTImsChalan, getUserChalan, pushToErp, getTImsChalanToVerify } from 'store/actions/requestActions';
import { CSVLink } from 'react-csv'
import { RootStateTypes } from 'ts/reducer';
import types from 'constants/types';
import isEmpty from 'helpers/isEmpty';
import Action from 'services/actionUtility';

function Chalan() {
    const { timsChalan, userChalan, timsChalanVerify } = useSelector((state: RootStateTypes) => state.requestReducer)
    return (
        <div className='chalan'>
            <div className="chalan-container">
                <div className="chalan-blocks">
                    <ChalanBlock title="Push Chalan To ERP" action={pushToErp} dt={timsChalan} fileName="tims-chalan" buttonName="Generate Bill" showAdditionalButton={true} vdt={timsChalanVerify} />
                    <ChalanBlock title="TIMS Chalan" action={getTImsChalan} dt={timsChalan} fileName="tims-chalan" buttonName="Download" />
                    <ChalanBlock title="Customer" action={getUserChalan} dt={userChalan} fileName="customer" buttonName="Download" />
                </div>
            </div>
        </div>
    )
}

const csvheaders = [
    { label: "Customer Code", key: "Customer_Code" },
    { label: "Customer Name", key: "Customer_Name" },
    { label: "PAN", key: "PAN" },
    { label: "Contact Number", key: "Contact_Number" },
    { label: "Email", key: "Email" },
    { label: "Address Date", key: "Address" },

]

const csvTImsheaders = [
    { label: "Sales Chalan Date", key: "sales_chalan_date" },
    { label: "Customer Code", key: "customer_code" },
    { label: "Item Code", key: "item_code" },
    { label: "Card Number From", key: "card_nofrom" },
    { label: "Card No To", key: "card_noto" },
    { label: "Quantity", key: "qty" },      
]

const ChalanBlock = (props) => {
    const { title = "", action, dt = [], fileName = "chalan", buttonName="", showAdditionalButton = false,vdt=[]} = props;
    const isCustomer = title === "Customer";
    const dts = dt ?? []
    const [csvData, setCsvData] = useState([]);
    const date = new Date()
    const ref = useRef(null)
    const today = isCustomer ? "" : dayjs(date).format("MM/DD/YYYY");
    const dispatch = useDispatch()
    const method = useForm({ defaultValues: { StartDate: today, EndDate: today } });
    const { watch } = method;
    const start = watch("StartDate")
    const end = watch("EndDate")
    useEffect(() => {
        if (!isEmpty(dts)) {
            setCsvData(dts ?? [])
            setTimeout(() => {
                if (ref.current) {
                    ref.current.link.click();
                    dispatch({ type: types.CLEAR_CHALAN })
                    setCsvData([])
                }

            }, 1000);
        }
    }, [dts])

    // Confirmation for generating the bill
    const handleGenerateBill = async (event) => {
        // Only trigger the confirmation for the "Generate Bill" button
        const buttddonName = event.target.title;
        const buttonName = event.target.textContent.trim() || event.target.innerText.trim();
        //if (event.target.type === "submit") {
        //    const confirmed = window.confirm("Are you sure you want to generate the bill?");
        //    if (confirmed) {
        //        // Proceed with the action to generate the bill
        //        await onImport();
        //    } else {
        //        console.log("Bill generation was canceled.");
        //        // Prevent default form submission behavior when canceled
        //        event.preventDefault();
        //    }
        //}
        const data = method.getValues();
        if (buttonName === "Generate Bill") {
            const confirmed = window.confirm(`Are you sure you want to generate the bill for ${buttonName}?`);
            if (confirmed) {
                // Proceed with the action to generate the bill
                await onImport(data);
            } else {
                console.log(`${buttonName} generation was canceled.`);
                // Prevent default form submission behavior when canceled
                event.preventDefault();
            }
        } else {
            // For other buttons (like "Download"), trigger the action directly without confirmation
            await onImport(data);
        }
    };
    const onImport = async (data) => {
        await dispatch(action({ startDate: data.StartDate, endDate: data.EndDate }))
       
        // New function to handle the additional button click (calling getTImsChalan)
      

        // Call ActionUtility.createThunkEffect to handle the action
        //const response = await Action.createThunkEffect({
        //    dispatch,
        //    actionType: types.PUSH_CHALAN_TO_ERP, // Replace with the actual action type
        //    effect: dispatch(action({ startDate: data.StartDate, endDate: data.EndDate })), // Replace with your effect
        //    message: "Chalan pushed successfully", // Success message
        //    showError: true,
        //    type: "post", // Use "post", "get", "put", etc., based on the action
        //});

        //// Handle response and show success/failure toast
        //if (response && response.Status === true) {
        //    toast.success(response.Message[0]); // Show success message if Status is true
        //} else {
        //    toast.error('Something went wrong while pushing chalan to ERP.'); // Show error message if something goes wrong
        //}
    }
    // New function for handling the additional button click
    //const onAdditionalButtonClick = async () => {
    //    // Dispatch action for getting TIMS Chalan to Verify
    //    const response = await dispatch(getTImsChalanToVerify({ startDate: start, endDate: end }));

    //    // Check if response data exists
    //    //if (response && response.data) {
    //        // Update CSV data with the response data
    //        setCsvData(vdt??[]);

    //        // Trigger the CSV download after data is fetched
    //        setTimeout(() => {
    //            if (ref.current) {
    //                ref.current.link.click();  // Trigger CSV download
    //            }
    //        }, 1000);
    //   // } else {
    //        // Handle the case where there's no response data
    //     //   console.error("Failed to fetch data for CSV download");
    //   // }
    //};

    const onAdditionalButtonClick = async () => {
        // Dispatch action for getting TIMS Chalan to Verify
        const response = await dispatch(getTImsChalanToVerify({ startDate: start, endDate: end }));

        // Check if response data exists
        if (vdt && vdt.length > 0) {
            // Update CSV data with the vdt (timsChalanVerify) data
            setCsvData(vdt);

            // Trigger the CSV download after data is fetched
            setTimeout(() => {
                if (ref.current) {
                    ref.current.link.click(); // Trigger CSV download
                }
            }, 1000);
        } else {
            console.error("Failed to fetch data for CSV download");
        }
    };

    return <><FormGroup method={method} onSubmit={onImport} >
        <div className="chalan-block">
            <div className="chalan-block__title">
                {title}
            </div>
            <div className="chalan-block__inputs">
                <div className="chalan-block__input">
                    <Form
                        name="StartDate"
                        formName="dateinput"
                        // type="date"
                        width="280px"
                        placeholder="Start date"
                        label="Start date"
                        className="required"
                        isClearable={isCustomer}
                        // required={!isCustomer}
                        // validate={(value)=>}!isCustomer
                        validate={(value) => {
                            if(!Boolean(value) && !isCustomer){
                                return "Start Date is required"
                            }
                            if(isCustomer && end && !Boolean(value)){
                                return "Start Date is required or clear end date to continue"
                            }
                            return true;
                        }}
                    />
                </div>
                <div className="chalan-block__input">
                    <Form
                        name="EndDate"
                        formName="dateinput"
                        // type="date"
                        width="280px"
                        placeholder="End date"
                        label="End date"
                        isClearable={isCustomer}
                        className="required"
                        // required={!isCustomer}
                        validate={(value) => {
                            if(!Boolean(value) && !isCustomer){
                                return "End Date is required"
                            }
                            if(isCustomer && start && !Boolean(value)){
                                return "End Date is required or clear start date to continue"
                            }
                            return true;
                        }}
                    />
                </div>

            </div>
            {showAdditionalButton && (
                <div className="chalan-block__btn">
                    <Button
                        bg="secondary"
                        title="Download Chalan To Verify"
                        onClick={onAdditionalButtonClick} // Triggering the action
                    />
                </div>
            )}
            <div className="chalan-block__btn">
                <Button
                    bg='primary'
                    title={buttonName}
                    htmlButtonType="submit"
                     onClick={handleGenerateBill}
                // onClick={()=>onImport()}
                />
            </div>
        
        </div>
    </FormGroup>
        <CSVLink
            headers={!isCustomer ? csvTImsheaders : csvheaders}
            filename={`${fileName}.csv`}
            data={csvData}
            ref={ref}
        />
    </>
}

export default Chalan