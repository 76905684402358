import RouteEnum from 'constants/routesEnum';
import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import Icons from '../icons/svgIcons';
import classnames from 'classnames';
// import logo from 'assets/images/logo_tims.png';
import logo from 'assets/images/logo.png';
import logoCollapse from 'assets/images/logo_collapse.png';
import useAuth from 'src/hooks/useAuth';
import {modulesList} from 'constants/contants';
import SvgIcons from 'components/icons/svgIcons';

function Sidebar() {
    const [collapse, setCollapse] = useState(false);
    const sidebarClass = classnames("sidebar", { "collapse": collapse });
    const history = useHistory()
    
    const pathman = history.location.pathname
    const isNotSidebar = pathman === RouteEnum.UploadDocuments || pathman.includes(RouteEnum.UploadDocumentsStatus) 
    if(isNotSidebar){
        return null;
    }
    
    return (
        <aside className={sidebarClass}>
            <div className="sidebar-sticky">
                <div className="space-between" style={{borderBottom:'1px solid #f2f2f2'}} >
                <div></div>
                {!collapse &&
                    <div 
                        onClick={()=>history.push("/")}   
                        style={{cursor:'pointer'}}     
                        id="tims-logo" className="sidebar-logo-text">
                     <img src={collapse ? logoCollapse : logo} alt="TIMS LOGO"/>
                    </div>}
                {/* {
                    !collapse && (
                        <div id="tims-logo" className="sidebar-logo-text">
                        <span>TIMS</span>
                    </div>
                    )
                } */}
                <div onClick={() => setCollapse(prev => !prev)} className="sidebar__menu">
                    <SvgIcons name="Burger"  color="#fff" />
                </div>
                </div>

                <CommonSidebar collapse={collapse} />
                {/* <div className="sidebar__footer-image">
                    <img src={collapse ? logoCollapse : logo} alt="Nepal Logo" />
                </div> */}
                {/* <div
                    onClick={() => setCollapse(prev => !prev)}
                    className="sidebar__footer">
                    <div className="sidebar__footer-icon"><Icons name="Collapse" /></div>
                    <div className="sidebar__footer-name">
                        <span>Collapse</span>
                    </div>
                </div> */}
            </div>

        </aside>
    )
}

export const CommonSidebar = (props) => {
    const {collapse,isPhone, onClose} = props;
    const allowed = useAuth()
    const onClick = () =>{
        if(isPhone){
            onClose()
        }
    }
    return(
        <>
        <div className="sidebar-logo">
                <div className="sidebar-logo__img">
                    {/* <img src={collapse ? logoCollapse : logo} alt="TIMS LOGO"/> */}
                    {
                        !collapse && isPhone && (
                            <div 
                                style={{color:'#00BA88',marginTop:15}}
                                id="tims-logo" className="sidebar-logo-text">
                            {/* <span>TIMS</span> */}
                            <img src={collapse ? logoCollapse : logo} alt="TIMS LOGO"/>
                        </div>
                        )
                    }
                </div>
                    {/* <div id="tims-logo" className="sidebar-logo-text">
                        <span>TIMS</span>
                    </div> */}
                </div>
                <div className="sidebar__body">
                    <ul className="sidebar__list">
                    {
                        allowed[modulesList.registerTrekkers] && (
                            <div className="sidebar__register">
                                <NavLink to={RouteEnum.NewRegister} exact activeClassName="active-register" className="sidebar__register-button">
                                    <span className="sidebar__register-icon">
                                        <Icons name={"UserAddAlt"} />
                                    </span>
                                    <span>Register Trek</span>
                                </NavLink>
                                {/* <NavLink to={RouteEnum.NewRegister + '/noreg/cips/failure?TXNID=8@-@618316778'} exact activeClassName="active-register" className="sidebar__register-button">
                                    <span className="sidebar__register-icon">
                                        <Icons name={"UserAddAlt"} />
                                    </span>
                                    <span>Register Trek</span>
                                </NavLink> */}
                            </div>
                        )
                    }
                        <SideLink onClick={()=>onClick()} path icon="Sd_Dashboard" label="Dashboard" name={modulesList.dashboad} to={RouteEnum.Dashboard}  />
                        <SideLink onClick={()=>onClick()} icon="Sd_Scan" stroke name={modulesList.scan} label="Scan" to={RouteEnum.Scan} />
                        <SideLink onClick={()=>onClick()} path icon="Sd_Users" label="Users"  name={modulesList.users} to={RouteEnum.Users} />
                        <SideLink onClick={()=>onClick()} icon="Sd_Trekking_History" color={'#52575C'} name={modulesList.trekkingHistory} label="Trekking History" to={RouteEnum.TrekkingHistory} />
                        {/* <SideLink icon="Bell" name={modulesList.inbox} label="Inbox" to={RouteEnum.Inbox} /> */}
                        
                    <SideLink onClick={() => onClick()} icon="Sd_Trekker" stroke color={'#52575C'} name={modulesList.trekkers} label="Trekkers" to={RouteEnum.Trekkers} />
 
                    <SideLink onClick={() => onClick()} icon="Sd_Scan" stroke color={'#52575C'} name={modulesList.selfScanTrekkers} label="Self Scanned" to={RouteEnum.SelfScanTrekkers} />
                        <SideLink onClick={()=>onClick()} path icon="Sd_Route"  name={modulesList.routes} stroke label="Route" to={RouteEnum.Route} />
                        <SideLink onClick={()=>onClick()} path icon="Sd_Invoice" color={'#52575C'}  name={modulesList.billing} label="Billing"  to={RouteEnum.Billing} />
                        <SideLink onClick={()=>onClick()} icon="Sd_TimsCard" color={'#52575C'}  name={modulesList.timsCard} label="TIMS Cards" to={RouteEnum.TimsCards} />
                        <SideLink onClick={()=>onClick()} icon="Sd_Request" color={'#52575C'}  name={modulesList.requests} label="Requests" to={RouteEnum.Request} />
                    <SideLink onClick={() => onClick()} isCustom icon={<span className='custom-sidebar-icon'><Icons name={"Remarks"} size={20} color='#000' /></span>} color={'#52575C'} name={modulesList.chalan} label="Invoice" to={RouteEnum.chalan} />
                    <SideLink onClick={() => onClick()} isCustom icon={<span className='custom-sidebar-icon'><Icons name={"Remarks"} size={20} color='#000' /></span>} color={'#52575C'} name={modulesList.chalanlog} label="Chalan Error Log" to={RouteEnum.chalanlog} />
                    <SideLink onClick={() => onClick()} isCustom icon={<span className='custom-sidebar-icon'><Icons name={"Remarks"} size={20} color='#000' /></span>} color={'#52575C'} name={modulesList.chalanlog} label="Pushed Chalan" to={RouteEnum.pushedChalanlog} />
                    <SideLink onClick={() => onClick()} icon="Sd_Trekker" stroke color={'#52575C'} name={modulesList.chalan} label="Deleted Trekkers" to={RouteEnum.DeletedTrekkers} />
                    <SideLink onClick={() => onClick()} icon="Sd_Trekker" stroke color={'#52575C'} name={modulesList.userPassword} label="Reset Password" to={RouteEnum.UserPassword} />
                    {/* <SideLink onClick={()=>onClick()} icon="Copy" color={'#52575C'}  name={modulesList.chalan} label="Invoice" to={RouteEnum.chalan} /> */}
                        {/* <SideLink onClick={()=>onClick()} icon="Sd_AddUser" color={'#52575C'}  name={modulesList.registerTrekkers} label="Register Trek" to={RouteEnum.NewRegister} /> */}
                        {/* <SideLink icon="Card" name="Reports" to={RouteEnum.Reports} /> */}
                    </ul>
                    {/* <div className="sidebar__seperator"></div> */}
                    {/* {
                        allowed[modulesList.registerTrekkers] && (
                            <div className="sidebar__register">
                                <NavLink to={RouteEnum.NewRegister} exact activeClassName="active-register" className="sidebar__register-button">
                                    <span className="sidebar__register-icon">
                                        <Icons name={"UserAddAlt"} />
                                    </span>
                                    <span>Register Trek</span>
                                </NavLink>
                            </div>
                        )
                    } */}

                </div>
        </>
    )
}


const SideLink = ({ name, label, icon, to, stroke, color, path, onClick, size=16,isCustom=false }: any) => {
    const allowed = useAuth()
    if(!allowed[name]){
        return null;
    }
    return <li 
        onClick={()=>onClick()}
        className="sidebar__item">
        <NavLink to={to} exact activeClassName="active-link" className="sidebar__link">
            <span className={classnames("sidebar__icon", { 'stroke-color': stroke, 'path-color': color || path, })}>
                {isCustom ? icon:<Icons name={icon} size={size} />}
            </span>
            <span className="sidebar__name">
                {label}
            </span>
        </NavLink>
    </li>
}


export default Sidebar
