import React, { useEffect, useState } from 'react'
import Container from 'components/layout/container';
import Table from 'components/table';
import Form from 'components/form';
import './styles.scss';
import Icons from 'components/icons/svgIcons';
import Modal from 'components/modal'
import { useForm, useWatch } from 'react-hook-form';
import Pagination from 'components/table/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { pageSizeValues } from 'constants/contants';
import { getBilling, getChalanlog } from 'store/actions/billingAction';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import environment from 'environment';
import { CountryFilter, DateFilter, PaymentMethodFilter, RegTypeFilter, TrekkingRouteFilter } from 'components/common/filters';
import ResetFilter from 'components/common/resetFilter';
import CommonSidebar from 'components/common/commonSidebar';
import Breadcrumbs from 'components/breadcrumb';
import RouteEnum from 'constants/routesEnum';


const ActionCells = ({value,row}) =>{
    const [open,setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    return(
        <div className="table-actions">
            <div 
                onClick={()=>setOpen(true)}
                className="table-actions__icon"><Icons name="Eye" /></div>
            {/* <div className="table-actions__icon"><Icons name="Download" /></div>
            <div className="table-actions__icon"><Icons name="Mail" /></div> */}
            <CommonSidebar title="Receipt Details" open={open} onModalClose={onModalClose}>
               {/* <InvoiceNew invoice={row.original} />*/}
            </CommonSidebar>
        </div>
    )
}



const csvheaders = [
    { label: "Invoice Id", key: "InvoceNumber" },
    { label: "Trekking Reagion", key: "TrekRoute" },
    { label: "Registration Type", key: "RegisterAgency" },
    { label: "Payment Medium", key: "PymentMethod" },
    { label: "Trekking Region", key: "TrekRoute" },
    { label: "Amount", key: "Amount" },
    { label: "Payment Date", key: "PaymmentDate" },
]

const Province = ({value}) =>{
    const { paymentMethods } = useSelector((state: RootStateTypes) => state.selectReducer)
    const provinceName = paymentMethods && value && paymentMethods.find(item=>item.value == value)
    return(
        <div className="">
            {
                provinceName && provinceName.label
            }
        </div>
    )
}

const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}

const filterState = {
    trekkingArea:"",
    paymethod:"",
    startDate:null,
    endDate:null,
    country:"",
    users:""
}

function ChalanLog() {
    const {control,register} = useForm();
    const dispatch = useDispatch();
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    const [filter, setFilter] = useState(filterState)
    const [page, setPage] = useState(pageState)
    const [data,setData] = useState([]);
    const [invoice,setInvoice] = useState(null);
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}));
    const {chalanlog} = useSelector((state:RootStateTypes)=>state.billingReducer)
    const user = useSelector((state:RootStateTypes)=>state.auth.user)
    const [open,setOpen] = useState(false);
    const onModalClose = () => {
        setOpen(false)
        setInvoice(null)
    };
    useEffect(()=>{
        if (chalanlog){
            setData(chalanlog)
            const totalRows = chalanlog ? chalanlog[0]?.TotalRows : 0;
            chalanlog && setPage(prev=>({...prev}))
            setPage(prev=>({...prev,totalRows:totalRows}))
        }
    }, [chalanlog]);
    useEffect(()=>{
        getBillings()
    },[page.pageSize,page.pageindex])
    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
    }
    const getBillings = () =>{
        dispatch(getChalanlog({...page,...filter}))
    }
    useEffect(()=>{
        getBillings()
    },[filter]) 
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    const columns = [
        //{
        //    Header: 'Invoice Id',
        //    accessor: 'Id',
        //    minWidth:150,
        //    isSort:true,
        //},

        {
            Header: 'Chalan Date',
            accessor: 'sales_chalan_date',
            //Filter:({filter:fil,onchange}) =><TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            minWidth: 100,
            isSort:true,
        },
        {
            Header: 'Date Error',
            accessor: 'sales_chalan_date_error',
            minWidth: 400,
            isSort:true,
            //Filter:({ filter:filt, onChange }) =><RegTypeFilter users={filter?.users} {...filt} onFilter={onFilter} />,
        },
        // {
        //     Header: 'Region',
        //     accessor: 'Regional',
        //     minWidth: 140
        // },
        // {
        //     Header: 'Country',
        //     accessor: 'Country',
        //     Filter:({ filter:filt, onChange }) =><CountryFilter country={filter?.country} {...filt} onFilter={onFilter} />,
        //     minWidth: 160
        // },
        {
            Header: 'customer_code',
            accessor: 'customer_code',
            //Filter:({filter:filt,onchange}) =><PaymentMethodFilter paymethod={filter?.paymethod} {...filter} onFilter={onFilter} />,
            minWidth: 100,
            isSort:true,
            // Cell:Province
        },
        {
            Header: 'card_nofrom',
            accessor: 'card_nofrom',
            minWidth: 100,
            isSort: false,
        },
        {
            Header: 'card_error',
            accessor: 'card_error',
            minWidth: 400,
            isSort: false,
        },
        //{
        //    Header: 'customer_id',
        //    accessor: 'customer_id',
        //    minWidth:175,
        //    isSort: false,
        //},
        {
            Header: 'customer_code_error',
            accessor: 'customer_code_error',
            minWidth: 400,
            //Filter: ({ filter: filt, onChange }) => <DateFilter {...filter} onFilter={onFilter} />,
            isSort: false,
        },
        // {
        //     Header: 'item_code',
        //     accessor: 'item_code',
        //     minWidth:200,
        //     isSort: false,
        //},
        //{
        //    Header: 'item_desc',
        //    accessor: 'item_desc',
        //    minWidth: 200,
        //    isSort: false,
        //},
        {
            Header: 'item_code_error',
            accessor: 'item_code_error',
            minWidth: 400,
            isSort: false,
        }
        , {
            Header: 'CreatedDate',
            accessor: 'CreatedDate',
            minWidth: 200,
            isSort: false,
        },

    ];
    const onClearFilters = () => setFilter(filterState)
    const onRowClick = (row) =>{
        setOpen(true)
        setInvoice(row.original);
    }
    const breadList = [{ title: 'Chalan Error Log', to: RouteEnum.chalanlog, isCurrent: true }]
    return (
        <>
            <Breadcrumbs isHome linkList={breadList} />
            <div className="billing common-container">
                <div className="billing-container">
                    <Container>
                        <div className="space-between search-alt">
                            <Form 
                                formName="reactSelect"
                                control={control}
                                name="pageSize"
                                value={pageSizeValues[0]}
                                defaultValue={pageSizeValues[0]}
                                width="175px"
                                className="page-nums"
                                placeholder="Choose..."
                                options={pageSizeValues}
                            />
                            {/*<div className="flex">*/}
                            {/*<ResetFilter onClearFilters={onClearFilters} filters={filter} />*/}
                            {/*    <DownloadAllCsv isData={false} headers={csvheaders} extraParams={{...filter}} fileName="ChalanLogs" api={environment.api.getChalanlog}  />*/}
                            {/*    <Form */}
                            {/*        register={register}*/}
                            {/*        name="search_route"*/}
                            {/*        width="300px"*/}
                            {/*        placeholder="Search..."*/}
                            {/*        formName="searchInput"*/}
                            {/*    />*/}
                                
                            {/*</div>*/}
                            
                        </div>
                        <div className="routes-table">
                            <Table  columns={columns} data={data} />
                            <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                        </div>
                    </Container>      
                </div>
                {
                    invoice && (
                        <CommonSidebar className="invoice-sidebar"  title="Receipt Details" open={open} onModalClose={onModalClose}>
                           {/* <InvoiceNew invoice={invoice} />*/}
                        </CommonSidebar>
                    )
                }

            </div>
        </>
    )
}

export default ChalanLog
