import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import getQueryUrl from 'helpers/getQueryUrl';



// export const getRequests = (page):any => async dispatch =>{
//     const finalUrl = getQueryUrl(environment.api.getRequest,page)
//     const res = https.get(finalUrl)
//     const actionConfig = {
// 		dispatch,
// 		actionType:types.GET_REQUESTS,
//         message:'',
// 		effect:res,
// 	}
// 	const response = await Action.createThunkEffect(actionConfig); 
//     console.log({response})
//     if(response && response.Status){
//         // history.push(RouteEnum.Login)
//     }
// }

export const getRequests = (page):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getRequest,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_REQUESTS,
        message:'',
        type:'get',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const addRequestNote= (data):any => async dispatch =>{
    const res = https.post(environment.api.requestNotes,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_REQUEST_NOTES,
        type:'post',
        message:'',
        isLoading:true,
        extraPayload:{types:'Remarks'},
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const pushToErp = (data): any => async dispatch => {
    const finalUrl = getQueryUrl(environment.api.pushChalan, data)
    const res = https.get(finalUrl)
    const actionConfig = {
        dispatch,
        actionType: types.GET_USER_CHALAN,
        message: 'Chalan have pushed successfully.',
        isLoading: false,
        effect: res,
    }
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
    }
}
export const getUserChalan = (data):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.userChalan,data)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_USER_CHALAN,
        message:'',
        isLoading:false,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getTImsChalan = (data):any => async dispatch =>{
    console.log({data})
    const finalUrl = getQueryUrl(environment.api.timsChalan,data)
    console.log({finalUrl})
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TIMS_CHALAN,
        message:'',
        isLoading:false,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getTImsChalanToVerify = (data): any => async dispatch => {
    console.log({ data })
    const finalUrl = getQueryUrl(environment.api.timschalanverify, data)
    console.log({ finalUrl })
    const res = https.get(finalUrl)
    const actionConfig = {
        dispatch,
        actionType: types.GET_TIMS_CHALAN_VERIFY,
        message: '',
        isLoading: false,
        effect: res,
    }
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
    }
}
export const approveDocument = (data):any => async dispatch =>{
    const res = https.put(environment.api.approveDocument,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_REQUEST_NOTES,
        type:'put',
        isLoading:true,
        extraPayload:{types:'Logs'},
        message:data && data.isRejectionNote ? "Document Rejected Successfully":'Document Approved Successfully',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getVouchers = (page):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getVoucher,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_VOUCHERS,
        message:'',
        type:'get',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const updateVouncherStatus = (data,onMClose=null):any => async dispatch =>{
    const res = https.put(environment.api.updateVoucherStatus,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_VOUCHER_STATUS,
        type:'put',
        message:'Voucher updated successfully',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        onMClose && onMClose(true)
        // history.push(RouteEnum.Login)
    }
}